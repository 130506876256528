<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <b> <p class="section-title">Codit.</p></b>
                        <!-- <img src="assets/img/logo2.png" alt="logo"> -->
                    </a>
                    <p>
                        We are a team of experienced trainers and IT professionals driven by a passion for sharing programming knowledge and coding expertise. Our mission is to empower individuals who are eager to learn and teach code by providing real-world insights and practical skills that are valuable for both freshers and working professionals.
                    </p>
                    <!-- <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/learntocode">Learn to Code</a></li>
                        <li><a routerLink="/codingtrainer">Became Coding Trainer</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="bx bx-map"></i>149, 2nd floor, Mount
                            Poonamallee Rd, above Axis Bank, Ramapuram, Chennai,
                            Tamil Nadu 600089
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i
                            ><a href="tel:+44587154756">+91 95851 13545</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i
                            ><a href="mailto:info&#64;codit.in"
                                >info&#64;codit.in</a
                            >
                        </li>
                        <!-- <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>
                        © Copyright Owned by
                        <a href="https://codit.in/" target="_blank">Codit.</a>
                    </p>
                </div>
                <!-- <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <!-- <div class="line"></div> -->
    </div>
</footer>
