<div class="container">
    <div class="section-title">
        <br>
        <span class="sub-title">To write code on your own</span>
        <h2>All you want is notebook and pen​</h2>
        <!-- <p>Finding your own space and utilize better learning options can result in faster than the traditional ways. Enjoy the beauty of eLearning!</p> -->
    </div>
    <div class="row">
        <!-- <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h3>Logic Building Bootcamp</h3>
                <p>Experienced teachers can assist in learning faster with their best approaches!</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div> -->
        <div class="col-lg-6 col-sm-4 col-md-4">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-brain-process"></i>
                </div>
                <h3>Learn to code</h3>
                <p>Logic building bootcamp to rewire your brain to code better.</p>
                <a routerLink="/learntocode" class="link-btn">Start Now!</a>
            </div>
        </div>
        <div class="col-lg-6 col-sm-4 col-md-4">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-computer"></i>
                </div>
                <h3>Became a coding trainer</h3>
                <p>Explore your career opportunity as coding trainer.</p>
                <a routerLink="/codingtrainer" class="link-btn">Start Now!</a>
            </div>
        </div>
        <!-- <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h3>Logic Building Bootcamp</h3>
                <p>Experienced teachers can assist in learning faster with their best approaches!</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div> -->
        <!-- <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-world"></i>
                </div>
                <h3>Enjoy Learning From Anywhere</h3>
                <p>We are delighted to give you options to enjoy learning from anywhere in the world.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div> -->
    </div>
</div>