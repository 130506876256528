<app-si-banner></app-si-banner>
<!-- <app-instructor-quote></app-instructor-quote> -->
<app-features></app-features>
<app-features-style-one></app-features-style-one>
<div class="premium-access-area ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>
