<!-- This section for Online Art Demo -->
<!-- <div
    class="oa-features-area pt-100"
    style="background-image: url(assets/img/online-art/features/features-bg.jpg);"
    [ngClass]="{'d-none': router.url === '/single-instructor'}"
>
    <div class="container">
        <div class="section-title oa-title-color">
            <span class="sub-title">EDUCATION FOR EVERYONE</span>
            <h2>Artists Spend Time for Your Enjoyment</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-sm-6">
                <div class="oa-features-card">
                    <img src="assets/img/online-art/features/img1.png" alt="icon">
                    <h3>Latest Skills</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="oa-features-card">
                    <img src="assets/img/online-art/features/img2.png" alt="icon">
                    <h3>Industry Experts</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="oa-features-card">
                    <img src="assets/img/online-art/features/img3.png" alt="icon">
                    <h3>Online Courses</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="oa-features-card">
                    <img src="assets/img/online-art/features/img4.png" alt="icon">
                    <h3>One to One</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
        </div>
        <div class="oa-video-box">
            <div class="image">
                <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                    <a
                        data-lg-size="1280-720"
                        data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                        data-sub-html="<p>Watch Video</p>"
                        class="d-block"
                    >
                        <div class="image">
                            <img src="assets/img/online-art/video/video.jpg" class="shadow" alt="video-image">
                        </div>
                        <div class="video-btn popup-youtube">
                            <i class="flaticon-play"></i>
                        </div>
                    </a>
                </lightgallery>
            </div>
            <div class="oa-video-shape-1" data-speed="0.06" data-revert="true">
                <img src="assets/img/online-art/video/shape1.png" alt="shape">
            </div>
            <div class="oa-video-shape-2" data-speed="0.06" data-revert="true">
                <img src="assets/img/online-art/video/shape2.png" alt="shape">
            </div>
            <div class="oa-video-shape-3" data-speed="0.06" data-revert="true">
                <img src="assets/img/online-art/video/shape3.png" alt="shape">
            </div>
        </div>
    </div>
</div> -->

<!-- This section for Single Instructor Demo -->
<div
    class="instructor-features-area pt-100 pb-70 d-none"
    [ngClass]="{'d-block': router.url === '/single-instructor'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="instructor-features-card">
                    <div class="content">
                        <div class="image-icon">
                            <img src="assets/img/instructor-home/features/img1.svg" alt="image">
                        </div>
                        <h3 class="el-messiri-font">Learn to Code Enhance Skills</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="instructor-features-card">
                    <div class="content">
                        <div class="image-icon">
                            <img src="assets/img/instructor-home/features/img2.svg" alt="image">
                        </div>
                        <h3 class="el-messiri-font">Learn in Your Own Pace</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="instructor-features-card">
                    <div class="content">
                        <div class="image-icon">
                            <img src="assets/img/instructor-home/features/img3.svg" alt="image">
                        </div>
                        <h3 class="el-messiri-font">Learn From Industry Experts</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>