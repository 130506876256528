<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Learn to Code</li>
            </ul>
            <h2>Learn to Code</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="courses-details-area ptb-100">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-image-style-two text-center">
                    <img src="assets/img/courses/img2.jpg" alt="image" />
                </div>
                <div class="courses-details-desc-style-two">
                    <h3>Logic Building Bootcamp</h3>
                    <p>
                        The bootcamp is designed to go from novice to expert in
                        coding. With no prerequisites required, it covers
                        everything from constructing simple programs to
                        understanding execution and debugging.
                        <strong
                            >Our goal is to help participants to develop logical
                            skills and write code effectively.</strong
                        >
                    </p>
                    <h3>Why choose this bootcamp?</h3>
                    <ul class="description-features-list">
                        <li>
                            <strong>Comprehensive Curriculum</strong>
                            – Covering everything from programming basics to
                            advanced techniques, ensuring participants have a
                            solid foundation in programming and problem solving
                            skills.
                        </li>
                        <li>
                            <strong>Hands-On Session</strong> – Interactive
                            session through coding exercises, allowing
                            participants to apply theoretical concepts and
                            improve their problem-solving skills.
                        </li>
                        <li>
                            <strong>Extended Mentor Support</strong> – Post-boot
                            camp, participants receive ongoing assistance in
                            practicing problems.
                        </li>
                        <li>
                            <strong>Join now, Pay later</strong> – We’re
                            confident you’ll benefit from the course. So join
                            our course, start learning today.
                        </li>
                    </ul>
                    <h3>This bootcamp Includes</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Instructor led class (Offline/online)
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Any programming language on your choice
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Coding exercises
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Assignments
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Practice in coding platform : leetcode,
                                    hackerrank
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Downloadable resources
                                </span>
                            </li>
                        </ul>
                    </div>
                    <h3>Who this course is for:</h3>
                    <ul class="audience-list">
                        <li>For beginners</li>
                        <li>Fresher and professionals</li>
                        <li>Any one who has passion to learn code</li>
                        <li>Programming hobbyists</li>
                        <li>College student looking to clear coding round</li>
                        <li>
                            School student who want a supplementary learning
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
