<!-- This section for Online Art Demo -->
<!-- <div
    class="oa-quote-area ptb-100"
    [ngClass]="{'d-none': router.url === '/single-instructor'}"
>
    <div class="container">
        <div class="oa-quote-inner-box">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="oa-quote-image">
                        <img src="assets/img/online-art/quote/quote.jpg" alt="quote-image">
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="oa-quote-content">
                        <img src="assets/img/online-art/quote/quotation.svg" alt="quotation">
                        <p>The artist world is limitless. It can be found anywhere, far from where he lives or a few feet away. It is always on his doorstep.</p>
                        <span>Paul Strand</span>
                    </div>
                </div>
            </div>
            <div class="quote-shape">
                <img src="assets/img/online-art/quote/shape.png" alt="shape">
            </div>
        </div>
    </div>
</div> -->

<!-- This section for Single Instructor Demo -->
<div class="instructor-quote-area ptb-100">
    <div class="container">
        <div class="instructor-quote-inner-box">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="instructor-quote-image">
                        <img
                            src="assets/img/instructor-home/quote/quote.png"
                            alt="image"
                        />
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="instructor-quote-content">
                        <img
                            src="assets/img/instructor-home/quote/quotation.svg"
                            alt="image"
                        />
                        <p>
                            Whether you want to uncover the secrets of the
                            universe, or you just want to pursue a career in the
                            21st century, basic computer programming is an
                            essential skill to learn.
                        </p>
                        <span>Stephen Hawking</span>
                    </div>
                </div>
            </div>
            <div class="quote-shape">
                <img
                    src="assets/img/instructor-home/quote/shape.png"
                    alt="image"
                />
            </div>
        </div>
    </div>
</div>
