<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
            <h2>Contact</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    <h2>Get in Touch</h2>
                    <p>
                        By joining us, you'll gain access to hands-on
                        experience, industry-relevant techniques, and the
                        confidence needed to excel in your coding journey.
                    </p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="bx bx-map"></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>
                                149, 2nd floor, Mount Poonamallee Rd, above Axis
                                Bank, Ramapuram, Chennai, Tamil Nadu 600089
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-phone-call"></i>
                            </div>
                            <h3>Contact</h3>
                            <p>
                                Mobile:
                                <a href="tel:+44457895789"
                                    >(+91) - 95851 - 13545</a
                                >
                            </p>
                            <p>
                                Mail:
                                <a href="mailto:info&#64;codit.in"
                                    >info&#64;codit.in</a
                                >
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-time-five"></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday :</p>
                            <p>06:00 am - 09:00 am</p>
                            <p>06:00 pm - 09:00 pm</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div
                    class="contact-form"
                    *ngFor="let Image of bgImage"
                    style="background-image: url({{ Image.img }});"
                >
                    <h2>Ready to Get Started?</h2>
                    <p>
                        Your email address will not be published. Required
                        fields are marked *
                    </p>
                    <form
                        #contactForm="ngForm"
                        (ngSubmit)="submit(contactForm.value)"
                    >
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input
                                        required
                                        minlength="3"
                                        maxlength="50"
                                        ngModel
                                        name="name"
                                        type="text"
                                        #name="ngModel"
                                        class="form-control"
                                        id="name"
                                        placeholder="Your name"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="name.touched && !name.valid"
                                    >
                                        <div *ngIf="name.errors.required">
                                            Name is required.
                                        </div>
                                        <div *ngIf="name.errors.minlength">
                                            Name is minimum
                                            {{
                                                name.errors.minlength
                                                    .requiredLength
                                            }}
                                            character.
                                        </div>
                                        <div *ngIf="name.errors.maxlength">
                                            Name is maximum 50 character.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input
                                        required
                                        ngModel
                                        name="email"
                                        type="text"
                                        #email="ngModel"
                                        class="form-control"
                                        id="email"
                                        placeholder="Your email address"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="email.touched && !email.valid"
                                    >
                                        Email Address is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input
                                        required
                                        ngModel
                                        name="number"
                                        type="text"
                                        #number="ngModel"
                                        class="form-control"
                                        id="number"
                                        placeholder="Your phone number"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="number.touched && !number.valid"
                                    >
                                        Phone number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea
                                        required
                                        ngModel
                                        #message="ngModel"
                                        name="message"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        class="form-control"
                                        placeholder="Write your message..."
                                    ></textarea>
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="message.touched && !name.valid"
                                    >
                                        Message is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn"
                                    [class.disabled]="!contactForm.valid"
                                >
                                    Send Message<span></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">
    <iframe
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=149,%202nd%20floor,%20Mount%20Poonamallee%20Rd,%20above%20Axis%20Bank,%20Ramapuram,%20Chennai,%20Tamil%20Nadu%20600089+(TERV%20PRO)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    ></iframe>
</div>
